export const appIdeaContent = [
  {
    id: "scalesWithOrganization",
    header: "Scales with your organization",
  },
  {
    id: "onBudget",
    header: "On budget and on time",
  },
  {
    id: "transparentProcess",
    header: "Transparent process",
  },
]

export const workWithUsContent = [
  {
    id: "flightPlan",
    header: "Flight Plan",
    body:
      "Don't build the wrong product. Work with us to develop a set of user stories and wireframes that will drive the development roadmap and process.",
  },
  {
    id: "systemCheck",
    header: "System Check",
    body:
      "Already have an established product? Let's bring it into the virtual hangar for an inspection. Evaluate architecture, code quality, security, and scalability.",
  },
  {
    id: "testLaunch",
    header: "Test Launch",
    body:
      "Looking to get started right away? Organize a trial sprint to get the development of your product off the ground.",
  },
]

export const appIdeaColumns = 3
export const workWithUsColumns = 3
