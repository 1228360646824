import React from "react"

import { Button, Icon } from "@launchacademy/voyager"

import Configuration from "../../Configuration.js"
import useCalendlyModal from "../calendlyModal/hooks/useCalendlyModal.js"

const ConsultingCallButton = ({
  location,
  text = "Schedule a Discovery Call",
  className = "",
  size,
  icon,
  secondary,
  placement,
  buttonMode = "button",
}) => {
  const header = (
    <>
      <h2>Schedule a Discovery Call</h2>
      <p className="pt-4">Let&apos;s chat about your custom software development project.</p>
    </>
  )
  const { modal, setModalVisibility } = useCalendlyModal({
    location,
    url: Configuration.consultingCallCalendlyUrl,
    header,
  })

  let button = (
    <Button
      to="#discoveryCall"
      state={{ showInfoCallDialog: true }}
      icon={icon}
      onClick={(e) => {
        e.preventDefault()
        setModalVisibility(true)
      }}
      secondary={secondary}
      placement={placement}
      className={`${className}`}
      text={text}
      size={size}
    />
  )
  if (buttonMode !== "button") {
    button = (
      <a
        href="#discoveryCall"
        className={className}
        onClick={(e) => {
          e.preventDefault()
          setModalVisibility(true)
        }}
      >
        {text}
      </a>
    )
  }
  return (
    <>
      {button}
      {modal}
    </>
  )
}

export default ConsultingCallButton
