import React, { FC } from "react"

import { FeatureListItemProps } from "./FeatureList.d"
import scroll from "./images/scroll.svg"

export const FeatureListItem: FC<FeatureListItemProps> = ({ title, subtitle, description }) => (
  <div className="grid__item">
    <div className="item__icon">
      <span>
        <img src={scroll} alt="Bullet" />
      </span>
    </div>
    <div className="item__content">
      <strong className="content__title">{title}</strong>
      <div className="content__subtitle">{subtitle}</div>
      <p className="content__description">{description}</p>
    </div>
  </div>
)
