import React from "react"

const RoundedCard = ({ overHeader, title, descriptionText, altText, Image }) => {
  let whichProfileClass = "rounded-card"

  return (
    <div className={whichProfileClass}>
      {Image && <Image className="rounded-card__image" />}
      <h6 className="rounded-card__over-header"> {overHeader} </h6>
      <h5 className="rounded-card__name">{title}</h5>
      <p className="rounded-card__content">{descriptionText}</p>
    </div>
  )
}

export default RoundedCard
