import React, { useEffect, createRef, useCallback, useRef } from "react"

import lottie from "lottie-web"
import { useInView } from "react-intersection-observer"

export const Lottie = ({ data, className = "", animateWhenInView = true }) => {
  let animationContainer = createRef()
  const { ref: inViewRef, inView } = useInView({
    delay: 1000,
    skip: !animateWhenInView,
    initialInView: false,
  })
  let anim = useRef()

  useEffect(() => {
    anim.current = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: data,
      name: "growth",
    })
    return () => anim?.current?.destroy() // optional clean up for unmounting
  }, [animationContainer, data])

  const animate = useCallback(() => {
    if (anim?.current && anim?.current?.isPaused) {
      anim.current.setDirection(1)
      anim.current.goToAndPlay(0)
    }
  }, [anim])

  useEffect(() => {
    if (inView) {
      animate()
    }
  }, [anim, inView, animate])

  return (
    <div ref={inViewRef}>
      <div
        className={`lottie-container ${className}`}
        onMouseEnter={() => {
          animate()
        }}
        ref={animationContainer}
      />
    </div>
  )
}
